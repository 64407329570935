.product-bundle-list {
    display: flex;
    align-items: center;

    .product-bundle-card {
        padding: 10px;
        width: calc(20% - 10px);
        @media (max-width: 1192px) {
            width: calc(25% - 10px);
        }
        @media (max-width: 992px) {
            width: calc(33.33333% - 10px);
        }
        @media (max-width: breakpoint(sm-end)) {
            width: calc(50% - 10px);
        }
        .product-card__image {
            margin-bottom: 10px;
            // .product-image__img {
            //     object-fit: cover;
            // }
        }
        .product-card__name {
            font-size: 14px;
            -webkit-line-clamp: 2;
            .outline-label {
                font-size: 10px;
                margin-right: 4px;
            }
        }
        .product-bundle-card__info {
            font-size: 14px;
            padding: 0 5px;
        }
        .product-variant-info {
            align-items: center;
            .fa-sort-down {
                position: relative;
                top: 1px;
            }
            .product__old-price {
                font-size: 14px;
            }
        }
    }

    .fa-plus {
        padding: 10px 12px;
        font-size: 20px;
        position: relative;
        top: -38px;
    }
}

.bundle-deals-section {
    display: flex;
    align-items: center;
    .combo-section,
    .section,
    .addon-section {
        margin: 20px 0;
        padding-right: 5px;
        position: relative;
        width: 100%;
    }
    @media (min-width: 992px) {
        .addon-section {
            border-right: $product-tabs-border;
        }
    }

    .section-title {
        font-size: 19px;
        padding: 15px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 15px;
        // border-bottom: $product-tabs-border;
        text-transform: uppercase;
        color: #2e2e2e;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .more {
            font-size: initial;
            text-transform: capitalize;
            color: $accent-color;
            font-weight: 400;
            cursor: pointer;
            white-space: pre;
            .fa-chevron-right {
                margin-left: 5px;
            }
        }
    }

    .combo-label {
        border: 1px solid $accent-color;
        color: $accent-color;
        white-space: pre;
        padding: 1px 4px;
        margin-right: 3px;
        position: relative;
        font-size: 13px;
        font-weight: 400;
        text-transform: capitalize;
        border-radius: 4px;
        display: flex;
        align-items: center;
    }

    .section-content {
        padding: 25px 0;
    }
    .bundle-total {
        padding-left: 15px;
        font-size: 15px;
        width: 20%;
        button {
            white-space: pre;
        }
        .product__old-price {
            font-size: 15px;
            margin: 2px 2px 2px 0;
        }
    }
    @media (max-width: 992px) {
        display: block;
        .section {
            border-right: none;
            padding-right: 0px;
        }
        .bundle-total {
            display: flex;
            width: 100%;
            border-top: $product-tabs-border;
            padding-top: 15px;
            justify-content: center;
            align-items: center;
            button {
                margin-left: 5px;
            }
        }
    }
}

.variant-popover-box {
    width: 450px;
    background-color: #fff;
    color: #2e2e2e !important;
    border: 1px solid #f0f0f0 !important;
    box-shadow: 0 5px 10px 0 rgba(240, 240, 240, 0.021) !important;
    padding: 20px !important;
    @media (max-width: 768px) {
        width: 100%;
        border: none !important;
    }
}
.right-bottom-qty {
    font-size: 13px;
    position: absolute;
    padding: 0px 7px;
    bottom: 0;
    background-color: grey;
    border-bottom-right-radius: $product-card-border-radius;
    color: white;
    right: 0;
}
.deals-table {
    .cart-table__column--price {
        display: initial;
        @media (max-width: 767px) {
            display: flex;
        }
    }
    .cart-table__column--variants {
        width: 25%;
        @media (max-width: 767px) {
            width: auto;
        }
    }
    .cart-table__row {
        border-bottom: 0;
    }
}
.add-cart-outline-btn {
    background-color: transparent;
    border: 1px solid $accent-color;
    color: $accent-color;
    @media (hover: hover) {
        &:hover {
            border: 1px solid $accent-color;
            background-color: $accent-color;
            color: white;
        }
    }
}

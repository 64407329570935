/*
// .footer-links
*/
@import "../variables";
@import "../functions";

.footer-links {
    color: $accent-color;
}
.footer-links__title {
    margin-bottom: 22px;
}
.footer-links__list {
    font-size: 14px;
    line-height: 28px;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: $accent-color;
        transition: 0.15s;
    }
    @media (hover: hover) {
        a:hover {
            color: #fff;
        }
    }
}
.footer-links__link {
    text-transform: capitalize;
}

@media (max-width: breakpoint(sm-end)) {
    .footer-links__title {
        margin-bottom: 12px;
    }
    .footer-links {
        margin: 12px 0;
        margin-bottom: 20px;
        text-align: center;
    }
}

.voucher-modal {
    max-width: 560px;
}
.voucher-list {
    max-height: 380px;
    overflow-y: auto;
    .voucher-box:first-child {
        margin-top: 8px;
    }
}

.voucher-box {
    margin: 18px 0;
    // margin-right: 1rem;
}

.platform-applied-voucher {
    color: #55cbba;
    padding: 1px 5px;
    border: 1px solid #55cbba;
    font-size: 12px;
    margin: 0 10px;
    white-space: nowrap;
    font-weight: 500;
    &.small {
        font-size: 11px;
        margin: 0;
    }
}

.voucher {
    display: flex;
    flex-wrap: wrap;
    // margin-right: -15px;
    align-items: center;
    position: relative;
    width: 100%;
    height: 120px;
    background-color: white;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);
    background-repeat-x: no-repeat;
    font-size: 16px;
    color: $dark-gray;
    box-sizing: border-box;

    @media (max-width: 576px) {
        height: 100px;
    }

    .left {
        position: relative;
        width: 75%;
        padding: 10px 15px 10px 20px;
    }

    .tnc-link {
        font-size: 13px;
        color: #4786cc;
        position: absolute;
        right: 10px;
    }

    .right {
        cursor: pointer;
        padding: 10px 15px;
        border-left: 1px dashed #ebebeb;
        width: 25%;
        height: 100%;
        &.selected {
            background-color: $light-accent-color
        }
        .claim-btn {
            @media (max-width: 576px) {
                @include btn-sm;
            }
        }
    }

    .name {
        font-size: 13px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 1.2;
    }

    .line {
        width: 6px;
        height: 70%;
        position: absolute;
        left: -1px;
        background-color: $accent-color;
    }

    .discount {
        line-height: 1.2;
        color: $dark-gray;
        font-size: 26px;
        font-weight: bold;
        span {
            margin-left: 8px;
            font-size: 15px;
        }
    }

    &.disabled {
        border: 1px solid #f2f2f2;
        color: #cfcfcf;
        box-shadow: unset;
        background-color: #f7f7f7;
        .line {
            background-color: #cfcfcf;
        }
        .label {
            color: #dbdbdb;
            border: 1px solid #dbdbdb;
        }
        .right {
            cursor: unset;
            border-left: 1px dashed #eaeaea;
        }
        .voucher-small-text,
        .discount {
            color: #cfcfcf;
        }
        .tnc-link {
            color: #dbdbdb;
        }
    }

    .unselect-circle {
        border: 1px solid #ebebeb;
        height: 23px;
        width: 23px;
        border-radius: 50%;
    }
    .select-circle {
        border: 1px solid #ebebeb;
        height: 23px;
        width: 23px;
        background-color: $accent-color;
        border-radius: 50%;
        text-align: center;
        i {
            position: relative;
            font-size: 14px;
            color: white;
            top: -1px;
        }
    }
    .label {
        color: #ff5d78;
        padding: 3px;
        border: 1px solid #ff5d78;
        font-size: 8px;
        top: -1px;
        font-weight: 500;
        position: relative;
    }
    @media (max-width: 420px) {
        .name {
            font-size: 12px;
        }
        .discount {
            font-size: 15px;
            span {
                font-size: 10px;
            }
        }
        .voucher-small-text {
            font-size: 10px;
        }
        .label {
            padding: 2px;
            font-size: 6px;
            top: 0px;
        }
        .left .tnc-link {
            font-size: 11px;
        }
    }

    &.voucher-claim {
        height: 180px;
        @media (max-width: 765px) {
            height: 150px;
        }
        .right {
            cursor: default;
        }
    }
}

.select-voucher-title {
    @media (max-width: 420px) {
        font-size: 13px;
    }
}

.check-voucher {
    i {
        position: absolute;
        font-size: 40px;
        top: -52px;
        left: 54px;
        color: #48af2c;
    }
}

.voucher-small-text {
    font-size: 12px;
    color: #979797;
    display: flex;
    flex-wrap: wrap;
}

.voucher-msg {
    font-size: 12px;
    padding-top: 5px;
    color: $medium-gray;
}

.voucher-input-box {
    align-items: center;
    padding: 15px 10px;
    background-color: #f6f6f6;
    display: flex;
    flex-wrap: wrap;
    .input-label {
        font-size: 15px;
        white-space: nowrap;
        @media (max-width: 768px) {
            margin-left: 8px;
        }
        @media (max-width: 420px) {
            font-size: 14px;
        }
    }
    input {
        margin: 0 8px;
        font-size: 14px;
        border-radius: 3px;
        &.active {
            border: 1px solid $accent-color;
        }
    }
    .btn-white {
        font-weight: initial;
        color: $light-gray;
        text-transform: uppercase;
        border: 1px solid #ced4da;
        height: calc(1.5em + 0.75rem + 2px);
        pointer-events: none;
        &:focus {
            box-shadow: none;
        }
        &.active {
            border: 1px solid $accent-color;
            background-color: $accent-color;
            color: white;
            pointer-events: initial;
        }
    }
}

.promo-result-wrapper {
    padding: 2px 5px;
}

.valid-promo-code {
    color: #28a745;
    font-size: 13px;
}

.voucher-info-wrapper {
    background-color: $block-highlighted-bg;
    align-items: center;
    justify-content: center;
    display: flex;
    .voucher-info {
        background-color: #fff;
        min-height: 100vh;
        .voucher-box {
            margin: 10px 20px;
            position: absolute;
            z-index: 1;
            width: -webkit-fill-available;
            top: 30px;
        }
        .voucher {
            cursor: initial;
            .label {
                font-size: 10px;
            }
            @media (max-width: 420px) {
                .name {
                    font-size: initial;
                }
                .discount {
                    font-size: initial;
                    span {
                        font-size: initial;
                    }
                }
                .voucher-small-text {
                    font-size: initial;
                }
                .label {
                    padding: 1px 3px;
                    font-size: 13px;
                    top: initial;
                }
                .left .tnc-link {
                    font-size: initial;
                }
            }
        }
        .top-bg {
            height: 110px;
            background-image: linear-gradient(to right, #4e65ff, #0093ff, #00b7ff, #20d5ff, #92effd);
        }
        .content {
            margin: 65px 22px 0px 22px;
            font-size: 14px;
            color: $medium-gray;
            overflow: hidden;
            overflow-y: auto;
            height: auto;
            label {
                font-weight: $font-weight-medium;
                color: $dark-gray;
                margin-bottom: 2px;
            }
        }
        .bottom-btn {
            margin-top: 12%;
            button {
                position: absolute;
                bottom: 25px;
                width: 90%;
                margin-left: 5%;
            }
        }
    }
}

/*
// .block-categories-carousel
*/
@import "../variables";
@import "../functions";
@import "../mixins/product-card";
@import "../mixins/preloader";

.block-categories-carousel {
    padding: 0 20px;
    .slick-track {
        padding-bottom: 56px;
        display: flex;
        align-items: stretch;
    }
    .slick-list {
        margin: 0 -5px;
        margin-bottom: -56px;

        &:hover {
            z-index: 2;
        }
    }
    .slick-slide {
        padding: 0 5px;
        height: auto;
        display: flex;

        &:focus,
        .correct-slick-active {
            outline: none;
        }

        & > div,
        & > div > div {
            width: 100%;
            display: flex !important;
            align-items: stretch;
        }
    }
    // @media (max-width: 579px) {
    //     padding: 0;
    // }
}
.block-categories-carousel__slider {
    position: relative;
}
.block-categories-carousel__preloader {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background: $block-categories-carousel-preloader-bg;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;

    &:after {
        @include preloader(100px);
    }
}
.block-categories-carousel--loading .block-categories-carousel__preloader {
    transition-delay: 0s, 0s;
    opacity: 1;
    visibility: visible;
}
.block-categories-carousel__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    // justify-content: center;
}
.block-categories-carousel__cell {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    height: 140px;
    width: 140px;
    // width: 100%;
    // height: auto;
    // max-height: 160px;

    & + & {
        margin-top: 10px;
    }

    .product-card {
        width: 100%;
    }
    img {
        border: 1px solid #efefef;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        &:hover {
            transition: box-shadow .2s;
            box-shadow: 0 0 8px 1px #bfbfbf;
        }
    }
}
.block-categories-carousel__category-name {
    font-size: 16px;
    color: #232323;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.2;
}
.block-categories-carousel--has-items {
    .block-categories-carousel__preloader {
        bottom: 56px;
    }
}

.block-categories-carousel[data-layout^="grid-"] {
    .product-card {
        @include product-card-grid();
    }
}
.block-categories-carousel[data-layout="grid-4"] {
    .product-card {
        @include product-card-grid-nl();
    }

    @media (min-width: 480px) and (max-width: breakpoint(lg-end)) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
}
.block-categories-carousel[data-layout="grid-4-sm"] {
    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: 479px) {
        .product-card {
            @include product-card-grid-nl();
        }
    }
}
.block-categories-carousel[data-layout="grid-5"] {
    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: 479px) {
        .product-card {
            @include product-card-grid-nl();
        }
    }
}

.block-categories-carousel[data-layout="horizontal"] {
    .product-card {
        @include product-card-horizontal();
    }
}
.categories-btn {
    text-transform: uppercase;
    width: 85%;
    height: 55px;
    margin: auto;
    color: $accent-color;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px 15px;
    white-space: pre-wrap;
    font-size: 13px;
    font-weight: 600;
    flex-grow: 1;
    transition: all 0.5s ease;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
}
.categories-btn:hover {
    background-color: $accent-color;
    color: #ffffff;
}

.carousel-before {
    position: absolute;
    top: calc(50% - 40px);
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #88848499;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: -18px;
    right: initial;
}

.carousel-before:hover {
    box-shadow: 0 0 7px 0 #88848499;
    cursor: pointer;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.carousel-before::before {
    content: "";
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 3px solid $accent-color;
    border-right: 3px solid $accent-color;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-25%, -50%) rotate(-135deg);
    -ms-transform: translate(-25%, -50%) rotate(-135deg);
    transform: translate(-25%, -50%) rotate(-135deg);
}

.carousel-after {
    position: absolute;
    top: calc(50% - 40px);
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #88848499;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: initial;
    right: -18px;
}

.carousel-after:hover {
    box-shadow: 0 0 7px 0 #88848499;
    cursor: pointer;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.carousel-after::before {
    content: "";
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 3px solid $accent-color;
    border-right: 3px solid $accent-color;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-75%, -50%) rotate(45deg);
    -ms-transform: translate(-75%, -50%) rotate(45deg);
    transform: translate(-75%, -50%) rotate(45deg);
}

/*
// .contact-us
*/
@import "../variables";
@import "../functions";

.contact-us {
    padding: 40px 0;
    color: #fff;
    a {
        color: inherit;
    }
    hr {
        border-top: 1px solid #181818;
        margin: 3rem 0;
    }
    .title {
        color: #6d6d6d;
        margin-bottom: 6px;
    }
}
.contact-us__map {
    position: relative;
    height: 440px;
    overflow: hidden;
    border-radius: 2px 2px 0 0;

    iframe {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
.big-page-title {
    font-size: 65px;
    font-weight: 600;
    text-transform: uppercase;
    span {
        color: $accent-color;
    }
}
.social-media-list {
    display: flex;
    a {
        margin: 0 15px 0 0;
        img {
            height: 25px;
            width: auto;
            aspect-ratio: 1/1;
        }
    }
}

@media (max-width: breakpoint(lg-end)) {
    .contact-us__map {
        height: 400px;
    }
}
@media (max-width: breakpoint(md-end)) {
    .contact-us__map {
        height: 380px;
    }
    .big-page-title {
        font-size: 55px;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .contact-us__map {
        height: 360px;
    }
    .big-page-title {
        font-size: 45px;
    }
}
@media (max-width: breakpoint(xs-end)) {
    .contact-us__map {
        height: 320px;
    }
}

/*
// .about-us
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.about-us {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    /* position the navbar center right */
    .navbar {
        position: fixed;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        @media (max-width: 576px) {
            right: 10px;
        }
    }

    /* style the individual nav items */
    .navbar a {
        /* make them little circles */
        display: block;
        border-radius: 50%;
        border: 1px solid $accent-color;
        width: 15px;
        height: 15px;
        cursor: pointer;
        /* with some space between them */
        margin: 18px 0;

        /* hide the text content */
        text-indent: -999px;
        overflow: hidden;

        /* establish positioning conext for the ::after pseudo-elements (below)*/
        position: relative;
        &.active {
            background-color: $accent-color;
        }
    }

    /* the "fill" */
    .navbar a::after {
        /* won't render without a 'content' rule */
        content: "";

        /* white fill */
        background-color: $accent-color;

        /* zero height until it's active */
        position: absolute;
        bottom: 0;
        height: 0;
        left: 0;
        right: 0;
        width: 100%;

        /* animate the height when it changes */
        transition: height 0.3s ease;
    }

    /* active and hovered elements */
    .navbar a:hover::after,
    .navbar a.active::after {
        /* change the height to 100%.
       the transition rule above will cause this to animate */
        height: 100%;
    }
}
.about-us__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    &.border-top-right {
        border-top-right-radius: 70px;
    }
    &.border-top-left {
        border-top-left-radius: 70px;
    }
}
.about-us__body {
    background: $body-bg;
    min-height: 380px;
    margin-top: -380px;
    border-radius: 4px 4px 0 0;
    padding: 75px 92px 45px;
}
.about-us__section {
    padding: 50px 0;
    .small-logo {
        width: 100%;
        img {
            width: 45%;
            height: auto;
            @media (max-width: 992px) {
                width: 55%;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
    &.gray {
        background-color: #f7f7f7;
        padding-bottom: 0px;
        .banner-img {
            max-width: 100%;
            margin-bottom: -75px;
        }
    }
    &.black {
        padding: 0px 0;
        margin: 50px 0;
        background-color: #000;
        .about-us__text {
            color: #fff;
        }
        @media (max-width: 768px) {
            margin: 30px 0;
        }
    }
    .banner-bg-gray {
        background-color: #f7f7f7;
        height: 300px;
        margin-top: -250px;
    }
    @media (max-width: 768px) {
        padding: 30px 0;
    }
}
.about-us__white-space {
    background-color: #fff;
    height: 100px;
    &.minus-margin-top {
        margin-top: -40px;
    }
}
.about-us__title {
    font-size: 35px;
    font-weight: $font-weight-bold;
    color: $accent-color;
    font-style: italic;
    margin-bottom: 25px;
    text-transform: uppercase;
}
.about-us__text {
    color: #000;
    .special-title {
        font-family: "Baskerville-italic", serif;
        font-size: 25px;
        padding-top: 10px;
        margin-bottom: 0;
    }
}
.special-title {
    font-size: 45px;
    font-family: "Baskerville-italic", serif;
    color: inherit;
    margin-bottom: 20px;
    @media (max-width: 576px) {
        font-size: 35px;
    }
}
.verticalLine {
    height: 250px;
    border-right: 1px solid #000900;
    position: relative;
    right: 50%;
}

@media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
    .about-us__title {
        margin-bottom: 36px;
    }
    .about-us__body {
        padding: 50px;
    }
    .about-us__team {
        margin-top: 75px;
    }
}
@media (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)) {
    .about-us__title {
        margin-bottom: 24px;
    }
    .about-us__body {
        min-height: 290px;
        margin-top: -290px;
        padding: 40px;
    }
    .about-us__team {
        margin-top: 50px;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .about-us__body {
        min-height: 290px;
        margin-top: -290px;
        padding: 30px 24px;
    }
    .about-us__title {
        margin-bottom: 20px;
    }
    .about-us__team {
        margin-top: 50px;
    }
}

/*
// .card
*/
@import "../variables";
@import "../functions";

.card {
    border: $card-border;
    border-radius: $card-border-radius;
}
.card-body {
    padding: 2rem;
}
.card-header,
.card-footer {
    padding: 20px 25px;
    border-top: none;
    border-bottom: none;
    background-color: transparent;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }
}
.card-title {
    margin-bottom: 1.75rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }
    span {
        font-size: initial;
    }
}
.card-divider {
    height: 2px;
    background: $card-border-color;
}
.card-body .card-title:last-child {
    margin-bottom: 0;
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .card-header,
    .card-footer {
        padding: 20px 25px;
    }
    .card-title {
        margin-bottom: 1.5rem;
    }
    .card-body {
        padding: 1.5rem;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .card-header,
    .card-footer {
        padding: 20px 25px;
    }
    .card-title {
        margin-bottom: 1.5rem;
    }
    .card-body {
        padding: 1.375rem;
    }
}
@media (max-width: 320px) {
    .card-body {
        padding: 23px;
    }
}

/*
// .totop
*/
@use "sass:math";
@import "../variables";
@import "../mixins/direction";

$local-size: 39px;
$local-bottom: 96px;
$local-margin-start: 80px;
$local-margin-end: 24px;

.totop {
    position: relative;
    z-index: 9;
    pointer-events: none;
}
.totop__body {
    position: fixed;
    bottom: $local-bottom;
    left: -12px;
    top: auto;
    width: 100%;
    display: flex;
    margin-top: -(math.ceil($local-size * 0.5));
    @media (max-width: 576px) {
        left: -8px
    }
}
.totop__container {
    margin: 0 -15px;
}
.totop__start,
.totop__end {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
}
.totop__end {
    position: relative;
    display: flex;
    justify-content: flex-start;
    // flex-direction: row-reverse;

    &:before {
        content: "";
        flex-grow: 1;
    }
}
.totop__button {
    pointer-events: auto;
    width: $local-size;
    height: $local-size;
    border-radius: $local-size * 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    cursor: pointer;
    color: $accent-color;
    background: transparent;
    border: 3px solid $accent-color;
    fill: currentColor;
    flex-shrink: 0;
    transition: background-color 0.15s, color 0.15s;

    @include direction {
        #{$margin-inline-start}: $local-margin-start;
        #{$margin-inline-end}: $local-margin-end;
    }

    &:focus {
        outline: none;
    }

    @media (hover: hover) {
        &:hover {
            color: $btn-primary-hover-font-color;
            background: $accent-color;
        }
    }

    &:active {
        color: $btn-primary-active-font-color;
        background: $btn-primary-active-bg-color;
    }

    svg {
        margin-top: -1px;
        margin-bottom: 1px;
    }
}

.whatsapp__button {
    pointer-events: auto;
    width: $local-size;
    height: $local-size;
    border-radius: $local-size * 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    // border: 3px solid $accent-color;
    cursor: pointer;
    color: $accent-color;
    background: transparent;
    fill: currentColor;
    flex-shrink: 0;
    transition: 0.5s ease;
    .fab {
        font-size: 43px;
    }
    @include direction {
        #{$margin-inline-start}: $local-margin-start;
        #{$margin-inline-end}: $local-margin-end;
        margin-bottom: -39px;
    }

    // &:hover,
    // &:hover i {
    //     color: white;
    // }

    // &:focus {
    //     outline: none;
    // }

    // @media (hover: hover) {
    //     &:hover {
    //         color: $btn-primary-hover-font-color;
    //         background: $btn-primary-hover-bg-color;
    //     }
    // }

    // &:active {
    //     color: $btn-primary-active-font-color;
    //     background: $btn-primary-active-bg-color;
    // }

    svg {
        margin-top: -2px;
        margin-bottom: 1px;
    }
}
.totop_btn_container {
    transition: opacity 0.3s, visibility 0s 0.3s;
    visibility: hidden;
    opacity: 0;
}
.totop_btn_container--show {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s, 0s;
}
.whatsapp__button--up {
    transition: 0.5s ease;
    margin-bottom: 11px !important;
}

/*
// .block-brands-carousel
*/
@import "../variables";
@import "../functions";
@import "../mixins/product-card";
@import "../mixins/preloader";

.block-brands-carousel {
    .slick-track {
        padding-bottom: 56px;
        display: flex;
        align-items: stretch;
    }
    .slick-list {
        margin: 0 -5px;
        margin-bottom: -56px;

        &:hover {
            z-index: 2;
        }
    }
    .slick-slider {
        padding: 5px 140px;
        @media (max-width: 765px) {
            padding: 5px 50px;
        }
        .slick-prev {
            fill: $accent-color;
            left: 40px;
        }
        .slick-next {
            fill: $accent-color;
            right: 40px;
        }
    }
    .slick-slide {
        padding: 0 5px;
        height: auto;
        display: flex;

        &:focus,
        .correct-slick-active {
            outline: none;
        }

        & > div,
        & > div > div {
            width: 100%;
            display: flex !important;
            align-items: stretch;
        }
    }
    .slick-dots{
        left: 50%;
        width: inherit;
    }
}
.block-brands-carousel__slider {
    position: relative;
}
.block-brands-carousel__preloader {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background: $block-brands-carousel-preloader-bg;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;

    &:after {
        @include preloader(100px);
    }
}
.block-brands-carousel--loading .block-brands-carousel__preloader {
    transition-delay: 0s, 0s;
    opacity: 1;
    visibility: visible;
}
.block-brands-carousel__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.block-brands-carousel__cell {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    & + & {
        margin-top: 10px;
    }

    .product-card {
        width: 100%;
    }
    img {
        max-width: 100%;
        width: 95px;
        height: 95px;
        object-fit: contain;
    }
}
.block-brands-carousel--has-items {
    .block-brands-carousel__preloader {
        bottom: 56px;
    }
}

.block-brands-carousel[data-layout^="grid-"] {
    .product-card {
        @include product-card-grid();
    }
}
.block-brands-carousel[data-layout="grid-4"] {
    .product-card {
        @include product-card-grid-nl();
    }

    @media (min-width: 480px) and (max-width: breakpoint(lg-end)) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
}
.block-brands-carousel[data-layout="grid-4-sm"] {
    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: 479px) {
        .product-card {
            @include product-card-grid-nl();
        }
    }
}
.block-brands-carousel[data-layout="grid-5"] {
    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: 479px) {
        .product-card {
            @include product-card-grid-nl();
        }
    }
}

.block-brands-carousel[data-layout="horizontal"] {
    .product-card {
        @include product-card-horizontal();
    }
}

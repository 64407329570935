/*
// .footer-contacts
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.footer-contacts {
    color: $accent-color;
}
.footer-contacts__title {
    margin-bottom: 25px;
}
.footer-contacts__text {
    font-size: 14px;
    line-height: 22px;
    list-style: none;
    margin: 0;
    padding: 0;
    color: $accent-color;

    // @include direction {
    //     #{$padding-inline-end}: 20px;
    // }

    a {
        color: $accent-color;
    }
}
.footer-contacts__contacts {
    font-size: 14px;
    line-height: 20px;
    list-style: none;
    padding: 0;
    // margin: 16px 0 0;

    a {
        color: $accent-color;
        @media (hover: hover) {
            &:hover {
                color: #fff;
            }
        }
    }

    li {
        padding: 4px 0;
        position: relative;
    }
}
.footer-contacts__icon {
    display: inline-block;
    text-align: center;
    width: 22px;

    @include direction {
        #{$margin-inline-end}: 2px;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .footer-contacts {
        text-align: center;
        margin-top: 42px;
    }
    .footer-contacts__title {
        font-size: 28px;
        margin-bottom: 16px;
    }
    .footer-contacts__text {
        padding: 0;
    }
}

/*
// .block-banner
*/
@import '../variables';
@import '../mixins/preloader';


.block-loader {
    position: relative;
    min-height: 320px;
}
.block-loader__spinner {
    @include preloader(80px);
}
.small-loader__spinner {
    @include preloader(20px);
}


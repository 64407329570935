.home-section1 {
    text-align: center;
    padding: 40px 0;
    p {
        color: #000;
    }
    .content {
        padding: 10px 20px 20px 20px;
    }
}

.gold-title {
    font-size: 65px;
    font-weight: $font-weight-bold;
    color: $accent-color;
    margin-bottom: 25px;
    text-transform: uppercase;
    &.small {
        font-size: 30px;
    }
    @media (max-width: 765px) {
        font-size: 55px;
        &.small {
            font-size: 23px;
        }
    }
    @media (max-width: 567px) {
        font-size: 30px;
        margin-bottom: 15px;
    }
}

.round-btn {
    background-color: $accent-color;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 20px;
    margin: 5px;
    height: auto;
    &.outline {
        font-size: 15px;
        border: 1px solid $accent-color;
        background-color: transparent;
        color: $accent-color;

        border-radius: 12px;
        margin: 0 5px 0 0;
    }
}
.home-section2,
.home-section4 {
    height: 100%;
    display: flex;
    align-items: center;
}
.home-section2 {
    .bg-img {
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: top;
    }
    .content-wrapper {
        padding: 12% 0;
        margin-top: 85px;
        text-align: center;
        .awards-list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .logo-box {
                margin: 5px;
                width: 80px;
                height: 80px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.white-space-title {
    padding: 6rem 10px;
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 768px) {
        padding: 45px 10px;
    }
    @media (max-width: 576px) {
        padding: 35px 10px;
        h3 {
            font-size: 25px;
        }
    }
}

.home-section4 {
    .bg-img {
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: top;
        padding: 8% 24%;
        @media (max-width: 768px) {
            padding: 8% 6%;
        }
    }
    .content-wrapper {
        color: #fff;
        padding: 8%;
        border-radius: 15px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
        .title {
            font-size: 30px;
            font-weight: $font-weight-bold;
            margin-bottom: 50px;
            text-transform: uppercase;
            @media (max-width: 768px) {
                font-size: 26px;
            }
        }
    }
}
.banner-with-text {
    position: relative;
    .text-content {
        position: absolute;
        z-index: 5;
        color: #fff;
        width: 20%;
        &.right {
            top: 25%;
            right: 17%;
        }
        &.left {
            top: 32%;
            left: 17%;
        }
        .title {
            font-size: 2.5vw;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            margin-bottom: 20px;
            white-space: break-spaces;
        }
        p {
            font-size: 17px;
        }
        @media (max-width: 768px) {
            top: 20%;
            .title {
                font-size: 4vw;
            }
        }
        @media (max-width: 576px) {
            top: 15%;
            width: 25%;
            p {
                font-size: 3vw;
            }
        }
    }
}
.banner-with-btn {
    position: relative;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    .img-fluid &.col-6 {
        aspect-ratio: 30/24;
    }
    .bottom-content {
        position: absolute;
        bottom: 8%;
        color: #fff;
        text-align: center;
        white-space: break-spaces;
        .btn {
            background: #fccc8a;
            border-color: #fccc8a;
        }
        @media (max-width: 576px) {
            font-size: 2.5vw;
            .btn {
                font-size: 0.875rem;
                height: calc(1.875rem + 7px);
                line-height: 1.25;
                padding: 0.6rem 1.2rem;
            }
        }
        @media (max-width: 420px) {
            font-size: 12px;
        }
    }
}
/* headlines with lines */
.decorated {
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 50px;
}
.decorated > span {
    position: relative;
    display: inline-block;
}
.decorated > span:before,
.decorated > span:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 34vw;
    margin: 0 20px;
    color: #000;
}
.decorated > span:before {
    right: 100%;
}
.decorated > span:after {
    left: 100%;
}
.home-event-banner {
    height: 350px;
    width: 100%;
    object-fit: cover;
}

// colors
$light-opposite-color: #3d464d !default;
$accent-color: #ddb881 !default;
$accent-opposite-color: #fff !default;
$link-color: $accent-color !default;
$link-hover-color: $accent-color !default;

// .nav-links
$nav-links-item-font-weight: 400 !default;
$nav-links-item-default-arrow-color: $accent-color !default;
$nav-links-item-hover-bg: rgba(#fff, 0.15) !default;

// .departments
$departments-bg: #303138 !default;
$departments-icon-color: rgba(#fff, 0.4) !default;
$departments-arrow-color: rgba(#fff, 0.4) !default;
$departments-item-hover-bg: rgba(#fff, 0.08) !default;

// .mobile-header
$mobile-header-menu-button-hover-bg: $nav-links-item-hover-bg !default;
// search input default
$mobile-header-search-input-default-bg: rgba(#fff, 0.4) !default;
$mobile-header-search-input-default-shadow: none !default;
$mobile-header-search-input-default-placeholder: rgba(#000, 0.5) !default;
$mobile-header-search-input-default-font-color: $light-opposite-color !default;
$mobile-header-search-input-default-icon-color: rgba(#000, 0.4) !default;
$mobile-header-search-input-default-icon-hover-color: $light-opposite-color !default;
// search input hover
$mobile-header-search-input-hover-bg: rgba(#fff, 0.6) !default;
// search input focus
$mobile-header-search-input-focus-bg: #fff !default;
$mobile-header-search-input-focus-shadow: 0 1px 5px rgba(#000, 0.2) !default;
$mobile-header-search-input-focus-placeholder: #999 !default;
$mobile-header-search-input-focus-font-color: $light-opposite-color !default;
$mobile-header-search-input-focus-icon-color: #b3b3b3 !default;
$mobile-header-search-input-focus-icon-hover-color: $light-opposite-color !default;

// .product-card
$product-card-hover-shadow: 0 0 0 2px #e5e5e5 inset !default;
$product-card-quickview-default-bg: #e5e5e5 !default;
$product-card-quickview-default-color: $light-opposite-color !default;

// .nouislider
$nouislider-border-color: $accent-color !default;
$nouislider-handle-color: $accent-opposite-color !default;

//custom
$header-bg: rgba(0, 0, 0, 0.7);
$footer-bg: #000;
$menu-font-color: #000;
$menu-bg: $accent-color;
$menu-item-arrow-color: #000;
$nav-links-item-default-font-color: #fff;
$mobile-header-menu-button-default-bg: $accent-color !default;
$border-grey: #f1f5f4;
$font-weight-medium: 500;
$header-search-input-default-icon-color: $accent-color;
$header-search-input-default-placeholder: $accent-color;
$header-search-height: 35px;
$footer-copyright-border: 1px solid $accent-color;
$header-search-input-focus-font-color: $accent-color;
$header-search-input-hover-font-color: $accent-color;
$footer-border-top: 1px solid #636262;
// ----------------------------------------
// -- .social-links
// ----------------------------------------
$social-links: () !default;
$social-links: map_merge(
    (
        facebook: (
            bg-color: $accent-color,
            font-color: #000,
        ),
        twitter: (
            bg-color: $accent-color,
            font-color: #000,
        ),
        youtube: (
            bg-color: $accent-color,
            font-color: #000,
        ),
        instagram: (
            bg-color: $accent-color,
            font-color: #000,
        ),
        rss: (
            bg-color: $accent-color,
            font-color: #3d464d,
        ),
    ),
    $social-links
);
$social-links-bg-color: #000 !default;
$social-links-font-color: #fff !default;
$body-font-color: #000;

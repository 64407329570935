// -------------------------
// --- You can add your variables to overwrite default values.
// -------------------------

// Uncomment one of the following line if you want to get appropriate theme.
@import "themes/gold";
//@import 'themes/blue';
//@import 'themes/black';
// @import 'themes/green';

$accent-color: #ddb881;
$second-color: #deba4f;
$light-accent-color: #fdf8e8;
$round-border: #dddddd;
$nav-panel-bg: #000;
$dark-gray: #2f2f2f;
$medium-gray: #646464;
$light-gray: #979797;
$background-gray: #fafafa;
$product-gallery-item-default-shadow: "none";
$product-tabs-border: 1px solid #b9b9b9;
$price-color: #2e2e2e;
$header-height: 80px;
$indicator-default-counter-bg: $accent-color;
$indicator-default-counter-font-color:#fff;
$indicator-default-counter-font-color: #fff;
$card-border-radius: 7px;